.drawer {
    width: 240;
    position: relative;
    z-index: 10000;
}

.user-container {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.user-item {
    padding: 0px !important;
}

.main-avatar-container {
    margin: auto !important;
}

.main-avatar {
    margin-left: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.main-username-container {
    margin: auto 0px !important;
}

.main-username {
    margin-right: 0px !important;
    margin-left: 10px !important;
    display: inline-block;
    font-weight: 700 !important;
}

.secondary-list {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    bottom: 42px !important;
    width: 100% !important;
}

.bottom-divider {
    display: block !important;
    position: absolute !important;
    bottom: 42px !important;
}

.version-container {
    display: flex !important;
    position: absolute !important;
    bottom: 0px !important;
    justify-content: center !important;
}

.chevron-right {
    margin: auto 0px !important;
    display: flex;
}

.menu-container {
    height: inherit;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
  }