.logo-container {
    transition: 0.2s ease all !important;
    animation: fade-in-right 300ms linear !important;
    padding-top: 6px;
    padding-bottom: 6px;;
    padding-left: 1rem;
}
.logo {
    height: 34px;
    /* margin: 4px auto;  */
    cursor: pointer;
}
.landing-logo {
  width: 222px;
}
@keyframes fade-in-right {
    from {
      opacity: 0;
      transform: translateX(-15px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }