/* #sv-library-page .react-swipeable-view-container > div {
	height: calc((var(--vh, 1vh) * 100) - 160px);
	overflow: hidden !important;
}
#sv-library-page .react-swipeable-view-container #searchTourtles, 
#sv-library-page .react-swipeable-view-container #collections {
	overflow: scroll !important;
	height: calc((var(--vh, 1vh) * 100) - 160px) !important;
	-webkit-overflow-scrolling: touch;
}

#sv-library-page .react-swipeable-view-container #toutleListBottomSpacing {
	display: none;
}
#sv-library-page .react-swipeable-view-container {
	height: calc(var(--vh, 1vh) * 100);
} */


#sv-library-page .react-swipeable-view-container > div {
	height: calc((var(--vh, 1vh) * 100) - 104px);
}
#sv-library-page .react-swipeable-view-container #searchTourtles,
#sv-library-page .react-swipeable-view-container #searchCollections {
	overflow: scroll !important;
	height: calc((var(--vh, 1vh) * 100) - 104px) !important;
	-webkit-overflow-scrolling: touch;
}
#sv-library-page .react-swipeable-view-container #toutleListBottomSpacing {
	display: none;
}
#sv-library-page .react-swipeable-view-container {
	height: calc((var(--vh, 1vh) * 100) - 104px) !important;
}