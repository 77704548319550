#sv-search-dialog .react-swipeable-view-container > div {
	height: calc((var(--vh, 1vh) * 100) - 56px);
	overflow: hidden !important;
	margin-top: 56px;
	/* -webkit-overflow-scrolling: touch; */
}
#sv-search-dialog .react-swipeable-view-container #searchTourtles,
#sv-search-dialog .react-swipeable-view-container #searchAuthors {
	overflow: scroll !important;
	height: calc((var(--vh, 1vh) * 100) - 56px);
	-webkit-overflow-scrolling: touch;
}
#sv-search-dialog .react-swipeable-view-container #toutleListBottomSpacing {
	display: none;
}
#sv-search-dialog .react-swipeable-view-container {
	height: calc(var(--vh, 1vh) * 100);
}

.tabContainer {
	padding: "0 !important";
}
