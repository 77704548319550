.search-field {
  margin-top: 60px;
  padding: 0 16px;
}

.search-input-input {
  padding: 8px 8px 8px 40px !important;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  width: 100%;
  border-bottom: solid rgba(0, 0, 0, 0.54) 2px !important;
}