.thingy {
  height: 100px;
}

.thingy .fadeout {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
}

.tourtle-list {
  flex-grow: 1;
  padding-bottom: 25px;
}

.subscription-author-container {
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  position: relative;
  width: 100%;
}

.member-container {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  position: relative;
  width: 100%;
}

.tourtle-item-thumb-icon {
  position: absolute;
  width: 35px !important;
  height: 35px !important;
  left: 35px;
  top: 30px;
}

.tourtle-item-thumb-text {
  font-size: 18px;
  position: absolute;
  width: 100%;
  bottom: 25px;
  text-align: center;
}

.tourtle-item-thumb-overlay {
  width: 105px;
  height: 105px;
  border-radius: 10px;
  background-color: #000;
  opacity: 0.2;
}

.tourtle-item-thumb-overlay-step {
  width: 140px;
  height: 100%;
  float: left;
  position: relative;
  min-height: 140px;
  background-color: #000;
  opacity: 0.2;
}

.tourtle-item-private-icon {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 45px;
  top: 40px;
}

.tourtle-item-private-icon-step {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 54px;
  top: 50px;
}

.tourtle-list-card {
  width: 100%;
  padding: 10px 5px 0px;
  cursor: pointer;
  flex-grow: 1;
}

.card-content {
  padding: 0 !important;
}

.tourtle-item-wrapper {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 125px;
  position: relative;
  display: flex;
  flex-direction: column;
}


.tourtle-item-wrapper-step {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 140px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tourtle-item-title-container {
  flex: 1;
  overflow: hidden;
  padding-right: 15px;
}

.tourtle-item-title-container-step {
  flex: 1;
  overflow: hidden;
  margin-top: 15px;
  padding-right: 15px;
}

.tourtle-item-content-container {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  padding-top: 0.25rem;
  line-height: 1rem !important;
}

.tourtle-item-content {
  float: left;
  margin-top: 4px;
}

.tourtle-item-collaborators {
  font-size: 18px !important;
  margin-right: 4px;
  margin-bottom: -4px;
  cursor: pointer;
}

.tourtle-item-likes {
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.54);
}

.tourtle-item-like-icon{
  height: 1.5rem;
}

.tourtle-item-like-icon-cover{
  height: 1.25rem;
}

.tourtle-item-detail {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.3rem;
}

.tourtle-item-rating {
  display: block;
  float: left;
}

.tourtle-item-view-count {
    line-height: 1.5rem;
}

.tourtle-item-like-count {
  margin-left: -0.25rem !important;
  line-height: 1.5rem;
}

/* .tourtle-item-favorite {
  position: absolute;
  float: right;
  bottom: 13px;
  right: 45px;
  height: 20px !important;
  border-radius: 0;
} */

.tourtle-item-checkbox-root {
  color: lightgrey !important;
  position: absolute !important;
  margin: 0px !important;
  right: 0px !important;
  bottom: 0px  !important;
}

.tourtle-item-checkbox-root-checked {
  color: #F47621 !important;
}

.tourtle-item-as-step {
  margin-bottom: 15px;
  margin-right: 0px;
  margin-left: 0px;
  cursor: pointer;
}
