.multi_line_title--container {
  white-space: nowrap;
  overflow: hidden;
}
.multi_line_title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  padding: 0 10px;
  letter-spacing: 0.0075em;
  margin: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.9rem;
}

.italic {
  font-style: italic;  
}