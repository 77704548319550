.attachment_input--container {
	width: 100%;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid lightgrey;
	height: 50px;
	margin-top: 14px;
	display: flex;
	align-content: center;
	justify-content: space-between;
	cursor: pointer;
}

.attachment_input {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.attachment_list_item {
	position: relative;
	cursor: pointer;
}

.name {
	border-top-style: hidden;
	border-right-style: hidden;
	border-left-style: hidden;
	border-bottom-style: hidden;
	border-radius: 5px;
	font-size: 1rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-size: 1rem;
	color: rgba(0, 0, 0, 0.54) !important;
	width: 100%;
}

.attachment_icon {
	padding: 2%;
	color: lightgrey;
}

.name:focus {
	outline: none;
	background-color: #eeeeee;
}
.removeAttachmentButton {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.file_input {
	max-width: 100px;
}

.rotate {
	transform: rotate(-45deg) !important;
}

.active_icon {
	color: #757575 !important;
}

.ellipsis-overflow {
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.tooltip {
	position: absolute;
	display: none;
	z-index: 50;
	max-width: 100%;
	left: 50%;
	bottom: 100%;
	transform: translate(-50%, -4px);
	word-wrap: break-word;
	white-space: break-spaces;

	color: #fff;
	padding: 4px 8px;
	font-size: 0.625rem;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	line-height: 1.4em;
	border-radius: 4px;
	background-color: #616161;
}

.dense-button-text:hover > .tooltip {
	position: absolute;
	display: block;
	-webkit-animation: fadeInDelayed 0.6s;
	animation: fadeInDelayed 0.6s;
}

@keyframes fadeInDelayed {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes fadeInDelayed {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.input_container {
	/* display: flex; */
	width: 100%;
	margin-left: 30px;
	padding: 0 8px !important;
}

.MuiTooltip-popper-367 {
	z-index: 1500;
	opacity: 0.9;
	pointer-events: none;
}
.MuiTooltip-popperInteractive-368 {
	pointer-events: auto;
}
.MuiTooltip-tooltip-369 {
	color: #fff;
	padding: 4px 8px;
	font-size: 0.625rem;
	max-width: 300px;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	line-height: 1.4em;
	border-radius: 4px;
	background-color: #616161;
}
.MuiTooltip-touch-370 {
	padding: 8px 16px;
	font-size: 0.875rem;
	line-height: 1.14286em;
}
.MuiTooltip-tooltipPlacementLeft-371 {
	margin: 0 24px;
	transform-origin: right center;
}
@media (min-width: 600px) {
	.MuiTooltip-tooltipPlacementLeft-371 {
		margin: 0 14px;
	}
}
.MuiTooltip-tooltipPlacementRight-372 {
	margin: 0 24px;
	transform-origin: left center;
}
@media (min-width: 600px) {
	.MuiTooltip-tooltipPlacementRight-372 {
		margin: 0 14px;
	}
}
.MuiTooltip-tooltipPlacementTop-373 {
	margin: 24px 0;
	transform-origin: center bottom;
}
@media (min-width: 600px) {
	.MuiTooltip-tooltipPlacementTop-373 {
		margin: 14px 0;
	}
}
.MuiTooltip-tooltipPlacementBottom-374 {
	margin: 24px 0;
	transform-origin: center top;
}
@media (min-width: 600px) {
	.MuiTooltip-tooltipPlacementBottom-374 {
		margin: 14px 0;
	}
}

.iframe_container {
	margin-top: 56px;
	height: 100%;
}
.video_container {
	display: grid;
	place-items: center;
	margin-top: 56px;
	height: calc(100vh - 56px) !important;
}

.video_container .react-player {
	max-height: 70% !important;
}

.not_supported_message--container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 80px;
	padding: 1rem;
}
.not_supported_message {
	font-size: 1rem !important;
}
