.step-item-blocked p {
    margin: 0;
    pointer-events: none !important;
    white-space: pre-wrap;
}
.step-item-blocked {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.step-item-blocked p,
.step-item-blocked p span,
.step-item-not-blocked {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 14px !important;
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}
.step-item-blocked ol {
    margin: 0;
}
.step-item-blocked ul {
    margin: 0;
}
