.tourtle-app-bar {
    &#{&} {
        box-shadow: unset;
        background-color: unset;
    }
    // border: 2px solid red;
}

.tourtle-app-bar span[class*="MuiPrivateTabIndicator"]{
    background-color: unset;
}

.tourtle-star-container {
    display: flex;
}

.tourtle-tab {
    &#{&} {
        opacity: .7;
        border-radius: 10px 10px 0 0;
        border: 1px solid rgba($gray-400, 0);
        border-bottom: unset;
        color: rgba(0, 0, 0, 0.54);
        font-weight: 500;
        background-color: $gray-300;

        &--selected {
            opacity: 1;
            border: 1px solid $gray-400;
            border-bottom: unset;
            border-width: 0.5px !important;
            color: $primary !important;
            background-color: $white;
        }
    }

    &__badge {
        &#{&} {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            gap: .5rem;
        }

        span {
            position: relative;
            transform: unset;

            &:first-of-type {
                font-size: $font-small;
                font-weight: 500;
            }

            &:last-of-type {
                background-color: $primary;
                color: $white;
            }
        }
    }
}

.tourtle-actions {
    flex-wrap: nowrap;
    gap: .75rem;
    width: 100%;
}

.tourtle-favorite-icon,
.tourtle-share-badge,
.tourtle-history-badge {
    svg {
        font-size: 1.25rem;
    }

    padding: .5rem !important;

    background-color: $white;
    cursor: pointer;
    color: $gray-600;
    // box-shadow: $box-shadow-sm;
    border-radius: 50%;
}

.tourtle-edit-badge {
    svg {
        font-size: 1.25rem;
    }

    padding: .5rem !important;

    background-color: $white;
    cursor: pointer;
    color: $gray-600;
    // box-shadow: $box-shadow-sm;
    border-radius: 50%;
    float: right;
}

.tourtle-share-badge {
    font-size: 12px;
    cursor: pointer;
}

.tourtle-history-badge {
    font-size: 12px;
    cursor: pointer;
}

.tourtle-edit-badge {
    &#{&} {
        padding: 0 .625rem;
        border-radius: 1rem;
        font-size: $font-tiny;
    }
}

.tourtle-edit-badge:hover {
    background-color: #e8e8e8;
}