@font-face {
    font-family: 'Marker-Felt';
      src:  url('/fonts/Marker-Felt.ttf.woff') format('woff'),
      url('/fonts/Marker-Felt.ttf.svg#Marker-Felt') format('svg'),
      url('/fonts/Marker-Felt.ttf.eot'),
      url('/fonts/Marker-Felt.eot?#iefix') format('embedded-opentype'); 
      font-weight: normal;
      font-style: normal;
  }

  .app-bar {
    min-height: 56px !important;
    z-index: 1000 !important;
    background-color: white !important;
    color: rgba(0, 0, 0, 0.54) !important;
  }

  /* .app-bar.bottom {
    top: unset;
    bottom: 0;
  } */

  .tool-bar {
    min-height: 56px !important;
    /* margin: auto;
    max-width: 715px; */
    width: 100%;
    
  }

  /* .top-bar-center-wrap {
    flex-grow: 1;
  } */

  .right {
    float: right;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  /* .right-icons {
    display: flex;
    align-items: center;
    margin-left: 15px!important;
  } */

  .right-moved {
    float: right;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: -12px;
  }

  .right-green {
    float: right;
    margin-top: 1px;
    margin-bottom: 1px;
    background: #00cc99;
    outline: white 7px solid;
    color: white;
    outline-offset: -6px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
  }

  .secondary-right {
    float: right;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .top-avatar-container {
    float: right;
    cursor: pointer;
    padding: 5px;
    width: 50px;
    height: 50px;
  }
  
  .sign-in-icon {
    float:right;
    width: 40px;
    height: 40px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 50%;
    border: 1px solid #000;
    color: #000;
    background: #fff;
    vertical-align: center;
    position: relative;
    cursor: pointer;
    padding: 4px;
  }

  .sign-in-text {
    position: absolute;
    top: 50%;
    transform: translate(-5%,-50%);
    text-align: center;
    color: #000;
    line-height: 100% !important;
    font-size: 0.8rem !important;
  }

  .secondary-right-green {
    float: right;
    background: #00cc99;
    outline: white 7px solid;
    outline-offset: -6px;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70px;
    color: white;
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .left {
    float: left;
    margin-left: -12px;
  }

  .tab-bar {
    position: fixed !important;
    top: 56px !important;
    z-index: 900 !important;
  }

  .secondary-tab-bar {
    position: fixed !important;
    padding-top: 60px !important;
    padding-bottom: 5px !important;
    z-index: 900 !important;
    box-shadow: none !important;
    background-color: white;
  }


.secondary-app-bar span[class*="MuiPrivateTabIndicator"]{
  background-color: unset;
}

.secondary-tab-selected {
  -moz-border-radius: 10px 10px 0px 0px !important;
  -webkit-border-radius: 10px 10px 0px 0px !important;
  border-radius: 10px 10px 0px 0px !important;
  -moz-border: 0.5px solid lightgray !important;
  -webkit-border: 0.5px solid lightgray !important;
  border: 0.5px solid lightgray !important;
  -moz-border-width: 0.5px 0.5px 0px 0.5px !important;
  -webkit-border-width: 0.5px 0.5px 0px 0.5px !important;
  border-width: 0.5px 0.5px 0px 0.5px !important;
  background-color: white !important;
  color: #F47621 !important;
}

.secondary-tab {
  -moz-border-radius: 10px 10px 0px 0px !important;
  -webkit-border-radius: 10px 10px 0px 0px !important;
  border-radius: 10px 10px 0px 0px !important;
  -moz-border: 0.5px solid lightgray !important;
  -webkit-border: 0.5px solid lightgray !important;
  border: 0.5px solid lightgray !important;
  -moz-border-bottom: 0.5px solid lightgray !important;
  -webkit-border-bottom: 0.5px solid lightgray !important;
  border-bottom: 0.5px solid lightgray !important;
  background-color: #eeecec !important;
}

/* .secondary-tab > span > span,
.secondary-tab-selected > span > span {
  padding-left: 3px;
  padding-right: 3px;
}

.secondary-tab span,
.secondary-tab-selected span {
  font-size: 0.75rem!important;
} */

  .title {
    margin-left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-wrap: break-word;
    font-size: 1rem;
    vertical-align: middle;
    overflow: hidden;
    hyphens: auto;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    height: 48px;
    line-height: 14px;
    cursor: pointer;
  }

  .group-search {
    width: 100%;
    position: relative;
    margin-top: -5px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .search-icon {
    width: 30px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clear-icon-left {
    width: 30px;
    height: 100%;
    right: 35px;
    margin-right: -10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .clear-icon-right {
    width: 30px;
    height: 100%;
    right: 5px;
    margin-right: -10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .sort-icon {
    width: 30px;
    height: 100%;
    right: 5px;
    margin-right: -10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .input-root {
    color: inherit;
    width: 100%;
  }

  .group-input-input {
    padding: 8px 8px 8px 40px !important;
    margin-right: 60px !important;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    width: 100%;
  }

  .top-bar-center {
    height: 100%;
    display: flex;
    align-items: center;
    color: black;
  }

  .top-bar-center-text {
    color: black;
  }

  .dropdown-menu:focus {
    outline-color: transparent;
  }

  .dropdown-sublist {
    padding-top: 25px !important;
  }
  