
.collection-list {
    flex-grow: 1;
  }
  
  .add-to-collection-collection-container {
    margin-top: 15px !important;
    margin-bottom: 48px !important;
    position: relative;
  }
  
  
  .add-to-collection-title {
    margin-top: 85px !important;
    padding-left: 15px !important;
    padding-right: 20px !important;
    width: 100% !important;
    display: -webkit-box !important;
    overflow: hidden !important;
    white-space: normal !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 2 !important;
    height: 2.8 !important;
    line-height: 1.4rem !important;
  }
  
  .add-to-collection-divider {
    margin-top: 5px !important;
    margin-bottom: 10px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  
  .collection-list-container {
    margin: 10px 0px 0px 0px !important;
    padding: 0 8px !important;
  }
  
  .collection-list-normal {
    margin-bottom: 10px;
    position: relative;
  }
  
  .collection-list-shadow {
    box-shadow: 0px 0px 14px 5px rgba(0,0,0,0.25);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 14px;
    right: 14px;
  }
  
  .collection-list-radio-button {
    position: absolute;
    color: white;
    top: 0;
    right: 0;
  }