.top-bar__save-button {
    background-color: black;
    box-shadow: 4px 4px 20px greenyellow !important;
    outline: 4px solid red !important;
    margin-right: .25rem;
}

.update-tourtle__disabled {
    opacity: 0.5;
    pointer-events: none;
}

.update-tourtle__info-text {
    border: solid 0.8px #38a4fb;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background-color: #E3F2FD;
    border-radius: 5px;

    .update-tourtle__info-text__icon {
        width: 20px;
        margin-left: 3px;
        fill: #38a4fb;
        padding: 2px;
      }

    .update-tourtle__info-text__text {
        margin: 4px;
        font-size: 0.75rem;
        color: #38a4fb;
      }
}

.tourtle-add-step-front {
    position: relative;

    &.px-0 {
        padding-left: 0;
        padding-right: 0;
    }

    &.add-step-bottom {
        margin-top: -10px;
    }

    .floating-title {
        position: absolute;
        top: 8px;
        left: 30px;
        font-size: 0.75rem;
        color: #808080;
    }
}

.list-btns-wrap {
    margin-top: 8px;
    border-radius: 10px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15)!important;
    overflow: hidden;

    &.no-shadow {
        filter: none;
    }

    .list-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.5rem;
        cursor: pointer;
        text-align: center;
        transition: background 0.2s;

        &:hover {
            background: rgba(0, 0, 0, 0.08);
        }
    }

    .list-btn-icon {
        color: #00cc99;
    }

    .list-btn-text {
        margin-bottom: 0;
        text-align: center;
    }
}

@media screen and (max-width: 599px) {
    .tourtle-add-step-front {
        .floating-title {
            display: none;
        }
    }
}