.category-input {
    $self: &;

    &#{&} {
        margin-top: 25px;
        border-radius: 10px;
        position: relative;
        background-color: #fff;
        border: 1px solid #e5e5e5;
    
        &:before,
        &:after {
            border-bottom: none;
        }
    
        &:hover::before {
            border-bottom: none !important;
        }

        #{$self}__menu {
            padding: .625rem 1.25rem;
        }
    
        #{$self}__select {
            &:focus {
                background-color: unset;
            }
        }
    
        #{$self}__icon {
            margin-right: 12px;
        }
    }
}