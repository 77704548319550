.audioPlayer {
    width: 100%;
    height: 32px;
    display: flex;
    background-color: #fff;
    border-radius: 10px;
    padding: .5rem 0;
}
.playButton {
    padding: 2px;
    width: 28px !important;
    height: 28px !important;
    margin-top: 2px;
    border: 3px solid #e4e4e4;
    border-radius: 50%;
    transition: all 0.2s ease;
}
.playButton:hover {
    border: 3px solid #F47621;
    cursor: pointer;
}
.audioPlayerPlay path:first-child {
    fill: #F47621;
}
.audioPlayerStop path:last-child {
    fill: #dcdcdc;
}
.audioPlayerStop:hover path:last-child {
  fill: #F47621;
}
.audioPlayerStop {
    margin-left: 10px;
    margin-right: 5px;
}
.mediaDuration {
    line-height: 32px;
    margin-left: 5px;
    color: #F47621;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}
.totalDuration {
  color: #adadad;
}
.mediaSlider {
    flex: 1;
    margin-left: 5px;
    padding: 0 5px;
}
input[type=range] {
    touch-action: none;
    -webkit-appearance: none;
    margin: 13px 0;
    width: 100%;
    background-color: #fff;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #e4e4e4;
    border-radius: 25px;
    border: 0px solid #000101;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 13px;
    width: 13px;
    border-radius: 10px;
    background: #F47621;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3.6px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #e4e4e4;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #e4e4e4;
    border-radius: 25px;
    border: 0px solid #000101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 13px;
    width: 13px;
    border-radius: 10px;
    background: #F47621;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 20px 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #F47621;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background: #e4e4e4;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 13px;
    width: 13px;
    border-radius: 10px;
    background: #F47621;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #F47621;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #e4e4e4;
  }
  