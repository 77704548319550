.ril {
    &__caption {
        font-family: 'Roboto';
        background-color: #FF000000 !important;
        padding: 10px 20px;

        .ril-caption-content {
            padding: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
    &__toolbar {
        font-family: 'Roboto';
        background-color: #FF000000 !important;
    }
}

.ril-outer {
    .ril-prev-button,
    .ril-next-button,
    .ril-toolbar-left,
    .ril-zoom-in,
    .ril-zoom-out,
    .ril-caption {
        transition: opacity 0.3s, visibility 0.3s;
        transition-delay: visibility 0.3s;

        &.hidden-controls {
            visibility: hidden;
            opacity: 0;
        }
    }
}