/* input, label {
  display: block;
} */

.file_input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file_upload {
  max-height: 350px;
  position: relative;
  display: flex;
  text-align: center;
  width: 100%;
  align-items: center;
}

.input_label {
  position: relative;
  min-width: 100%;
  min-height: 50px;
  z-index: 1;
  height: 48px;
  width: calc(100% - 64px);
  border: solid 1px lightgrey;
  border-radius: 10px;
  cursor: pointer;
}

.input_label--hover {
  position: relative;
  min-width: 100%;
  min-height: 50px;
  z-index: 1;
  height: 48px;
  width: calc(100% - 64px);
  border: solid 1px lightgrey;
  background:#f1f1f1;
  border-radius: 10px;
  cursor: pointer;
}

.loading_circle {
  position: absolute;
  left: 14px;
  top: 9px;
  z-index: 200;
}

.optional {
  color: #bdbbbb;
  text-align: center;
  cursor: pointer;
  margin: 0;
  font-size: 16px;
}

.optional_hover {
  color: #969696;
  text-align: center;
  cursor: pointer;
  margin: 0;
  font-size: 16px;
}

.with_data {
  height: 48px;
  width: calc(100% - 64px);
}

.without_data {
  height: 100%;
  width: 100%;
  background-color: "#f1f1f1";
}

.figure {
  display: flex;
  align-items: center;
  margin-right: 10px;
  border-radius: 50%;
  /* background-color: #cccccc; */
  padding: 20px;
  cursor: pointer;
  margin: 0;
}
.figure_hovered {
  display: flex;
  align-items: center;
  margin-right: 10px;
  border-radius: 50%;
  /* background-color: #b9b9b9; */
  padding: 20px;
  cursor: pointer;
  margin: 0;
}
.icon {
  /* color: #fff; */
  color: #cccccc;
}
.close_button {
  background-color: #f1f1f1;
  right: 0;
  top: 50%;
  margin-top: -24px;
}
.dimming {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.5);
  z-index: 100;
}

.file_viewer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-height: 50px;
}

.document {
  max-width: 400px;
}
canvas {
  max-width: 400px;
  max-height: 580px;
}

.button_text {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.data-class {
  width: 100%;
}

.dense-button-container {
  margin-top: 15px;
  border: 1px solid #DCDCDC !important;
  border-radius: 10px !important;
}

.dense-button-text {
  color: rgba(0, 0, 0, 0.54) !important;
}

.box {
  max-height: 350px;
  position: relative;
  display: flex;
  text-align: center;
  width: 100%;
  align-items: center;
}