#sv-about-author .react-swipeable-view-container > div {
	height: calc((var(--vh, 1vh) * 100) - 104px);
}
#sv-about-author .react-swipeable-view-container #searchTourtles {
	overflow: scroll !important;
	height: calc((var(--vh, 1vh) * 100) - 104px) !important;
	-webkit-overflow-scrolling: touch;
}
#sv-about-author .react-swipeable-view-container #toutleListBottomSpacing {
	display: none;
}
#sv-about-author .react-swipeable-view-container {
	height: calc((var(--vh, 1vh) * 100) - 104px) !important;
}

.swipeable-views {
	padding-top: 116px;
}

.info-block {
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    float: left;
    margin-top: 0px;
}

.image-container {
    width: 40%;
    float: left;
    position: relative;
    padding-bottom: 40%;
    height: 0px;
}

.image-container-fullscreen {
    color: rgba(0, 0, 0, 0.54);
    width: 2rem !important;
    height: 2rem !important;
    padding: 0.3rem !important;
    position: absolute;
    bottom: 5%;
    right: 5%;
    background-color: rgb(244, 244, 244);
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
}

.thumb {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    left: 0px;
}

.author-block {
    float: right;
    width: 60%;
    position: relative;
    padding-left: 20px;
}

.username {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
}

.tourtle-count {
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    margin-left: 2px !important;
}

.subscriber-count-link {
    color: blue !important;
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    margin-left: 2px !important;
    cursor: pointer !important;
}

.divider {
    /* margin-top: 12px !important; */
    /* margin-bottom: 5px !important; */
    margin-top: 5px !important;
}

.button {
    text-transform: none !important;
    margin-top: 10px !important;
}

.second-button {
    text-transform: none !important;
    margin-top: 10px !important;
    padding: 6px 5.75px !important;
}

.customWidth {
    max-width: none;
}

.share-button {
    display: inline-flex !important;
    vertical-align: middle;
    margin-left: 15px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 1.6rem !important;
}

.add-alert {
    color: rgba(0, 0, 0, 0.54);
    display: inline-flex !important;
    vertical-align: middle;
    margin-left: 15px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 1.6rem !important;
}

.description-block {
    width: 100% !important;
    padding: 20px !important;
    float: left !important;
}

.member-since {
    font-size: 1rem !important;
    font-weight: 500 !important;
}

.user-bio {
    font-size: 16px !important;
    font-style: italic !important;
    padding-top: 10px !important;
    word-break: break-word !important;
}

.item-and-edit {
    margin-bottom: 1.5rem !important;
}

.item-and-edit h4 {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0;
}

.edit-subscription-pencil {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem !important;
    vertical-align: text-top;
    margin-left: 0.25rem;
    cursor: pointer;
}

.author-info {
    padding-top: 75px;
}

.subheader {
    padding-left: 20px;
    padding-right: 20px;
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    padding-bottom: 10px;
}