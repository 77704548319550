.loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgb(244 118 33 / 70%);
    padding: 1rem;
    color: #fff;
    font-family: 'Roboto';
}

.loading-overlay .uploading,
.loading-overlay .creating-step {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
}

.loading-page {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    top: 0;
    background: rgb(244 118 33 / 30%);
    z-index: 2;
}