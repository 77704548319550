// This is the <Editor /> component
.description-input {
    &__wrapper {
        &#{&} {
            font-family: Roboto;
            margin-top: 25px;
    
            position: relative;
    
            rdw-link-modal-btn {
                font-weight: 400;
            }
        }
    }

    &__toolbar {
        &#{&} {
            z-index: 200;
            display: flex;
            justify-content: flex-end;
            background-color: whitesmoke;
            margin-bottom: 0px;
            position: absolute !important;
            bottom: 0px !important;
            width: 100%;
            padding: 6px 0px 6px !important;
            border: 0px solid #fff !important;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    &__editor {
        &#{&} {
            height: 100%;
            padding: 0.625rem 1rem;
            overflow: auto;
            border-radius: 10px;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            position: relative;
            padding-bottom: 100px;
        }
    }
}
