.text-button {
  text-transform: none !important;
  font-size: 17px !important;
  height: 48px;
}

.text-button-container {
  height: 48px;
}


.text-button-disabled {
  text-transform: none !important;
  font-size: 17px !important;
  height: 36px;
  border-radius: 10px !important;
  background-color: #F47621 !important;
  color: white !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  box-shadow: unset !important;
  opacity: 0.5;
}

.text-button-outline {
  text-transform: none !important;
  font-size: 17px !important;
  height: 36px;
  border-radius: 10px !important;
  background-color: #F47621 !important;
  color: white !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  box-shadow: unset !important;
  cursor: pointer;
}
