.thingy {
  height: 100px;
}

.thingy .fadeout {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
}

.card {
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
  flex-grow: 1;
}

.content {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.author-list-thumb {
  width: 80px !important;
  height: 80px !important;
  float: left;
  position: relative !important;
  margin-top: 10px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.wrapper {
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
  min-height: 100px;
  position: relative;
}

.title-block {
  padding-right: 15px;
}

.author-list-title {
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: auto;
  line-height: 1.3rem;
  overflow: hidden;
  /* -webkit-line-clamp: 2; */
  /* -webkit-box-orient: vertical; */
  /* display: -webkit-box; */
}

.author-list-tourtle-count {
  font-size: 0.7rem !important;
  font-weight: 400 !important;
  margin-left: 2px !important;
}

.subscribe-button {
  position: absolute;
  margin: 0px;
  right: 60px;
  bottom: 30px;
}

.button {
  text-transform: none;
  margin-top: 10;
}

.notification-icon {
  z-index: 99;
  position: absolute;
  margin: 0;
  right: 17px;
  bottom: 30px;
}

.three-dot {
  z-index: 99 !important;
  position: absolute !important;
  margin: 0px !important;
  right: 5px !important;
  bottom: 5px !important;
}

.three-dot-top {
  z-index: 99 !important;
  position: absolute !important;
  margin: 0 !important;
  right: 5px !important;
  top: 5px !important;
}