.chip-input {
    &__root {
        &#{&} {
            margin-top: 25px;
        }
    }

    &__input-root {
        &#{&} {
            margin: 0;
        }
    }

    &__input-tags {
        &#{&} {
            font-size: 1rem;
        }
    }

    &__container {
        &#{&} {
            border-radius: 10px;
            position: relative;
            background-color: $white;
            border: 1px solid #E5E5E5;
            min-height: 36px;
            padding: 4px 1.25rem 3px;
    
            &:before,
            &:after {
                border-bottom: none;
            }
    
            &:hover::before {
                border-bottom: none !important;
            }
        }
    }

    &__chip-tags {
        &#{&} {
            height: 26;
            margin: 4px 5px 4px 0px;
        }
    }
}
