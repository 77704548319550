body {
    background-color: #ffffff !important;
	-webkit-text-size-adjust: 100%;
}

.hr-sect {
	display: flex;
	flex-basis: 100%;
	align-items: center;
	color: rgba(0, 0, 0, 0.35);
	font-size: 12px;
	margin: 0px 0px 0px 0px;
}
.hr-sect > p {
	margin: 0px 16px;
	font-size: 0.75rem;
}
.hr-sect::before,
.hr-sect::after {
	content: "";
	flex-grow: 1;
	background: rgba(0, 0, 0, 0.35);
	height: 1px;
	font-size: 0px;
	line-height: 0px;
}