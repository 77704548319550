.upload-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5rem;

    div {
        margin: 0 !important;
        padding: 0 !important;
    }
}
