.max-height-container {
    height: 64px;
}

.author-container {
    display: inline-block;
    position: relative;
}

.new-content-indicator {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    width: 15px;
    height: 15px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #F47621;
}

.avatar-container {
    cursor: pointer;
    margin-right: 15px;
    display: block;
    position: relative;
    height: 64px;
    width: 64px;
    overflow: hidden;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    color: #fafafa;
    background-color: #bdbdbd;
}

.avatar {
    position: absolute;
    height: 100%;
    width: 100%;
    line-height: 64px;
    text-align: center;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 1.25rem;
}

.all-button {
    cursor: pointer;
    position: absolute;
    top: 0px;
    height: 64px;
    line-height: 59px;
    right: 0px;
    width: 60px;
    text-align: center;
    z-index: 100;
    border-left: solid #efefef 2px;
    font-size: 0.875rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
    color: #909090;
}

.bar-container {
    position: relative;
}

.subscriptions-container {
    justify-content: space-around;
    overflow-x: initial;
    overflow-y: hidden;
    margin-right: 60px;
    white-space: nowrap;
    padding: 0 0 0 15px;
    position: relative;
}