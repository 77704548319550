.group-container {
    margin: 0px !important;
    padding: 0 8px !important;
}

.layer {
    mask-image: linear-gradient(rgba(237, 105, 64, .7), rgba(237, 105, 64, .7));
}

.radio-button {
    position: absolute;
    top: 0px;
    right: 0px;
}

.radio {
    color: white;
}

.shadow {
    box-shadow: 0px 0px 14px 5px rgba(0, 0, 0, 0.25);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: 14px;
    right: 14px;
}

.invitations-badge {
    margin-left: 20px;
    margin-bottom: 2px;
}

.group-description-block {
    position: relative;
    padding-bottom: 150px;
}

.group-description-block p {
    margin-block-start: 0px;
    margin-block-end: 0px;
    line-height: 1.4em;
    min-height: 19px;
    font-size: 18px;
}

.group-description p {
    white-space: pre-wrap;
}
[class$='avatar-50'] > img {
    display: block;
    border-radius: 200px;
    box-sizing: border-box;
    background-color: #DDD;
    border: 3px solid white;
}


.group-app-bar {
    box-shadow: none !important;
}

.group-app-bar span[class*="MuiPrivateTabIndicator"]{
    background-color: unset;
}

.group-tab-selected {
    border-radius: 10px 10px 0px 0px !important;
    border: 0.5px solid lightgray !important;
    border-width: 0.5px 0.5px 0px 0.5px !important;
    background-color: white !important;
    color: #F47621 !important;
}

.group-tab {
    border-radius: 10px 10px 0px 0px !important;
    border: 0.5px solid rgba(255, 255, 255, 0) !important;
    border-bottom: 0.5px solid lightgray !important;
    background-color: #eeecec !important;
    color: rgba(0, 0, 0, 0.54) !important;
}

.tab-badge span {
    position: relative;
    transform: unset;
    margin-left: .25rem;
    aspect-ratio: 1/1;
}

.add-tourtle-fab {
    bottom: 65px;
}