.bottom-navbar-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1000;
}

.bottom-navigation {
    width: 100%;

    button {
        min-width: 60px;
    }
}

.unique-item svg {
    transform: scale(1.2);
    color: #f47621;
}