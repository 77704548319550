.player-wrapper {
  /* padding-bottom: 56.25%;
  height: 0; */
  width: 100%;
}
.react-player {
  height: auto !important;
  width: 100%;
}

.react-player video {
  max-height: 350px;
  background-color: #333333;
}

.react-player > div {
  position: relative;
}

.react-player__preview {
  padding-bottom: 56.25%;
  position: relative;
}

.react-player__shadow {
  top: 50%;
  margin-top: -32px;
  position: absolute;
}