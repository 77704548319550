.icon_container {
  background-color: #E3E4E6;
  height: 26px;
  width: 26px;
  display: grid;
  place-items: center;
  border-radius: 5px;
  position: absolute;

}
.icon_text {
  font-size: 0.66rem;
  font-weight: 500;
  color: #8B8B8B;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}