.add-to-group-root {
    padding-right: 0px !important;
    overflow: hidden;
}

.add-to-group-title {
    margin-top: 85px !important;
    padding-left: 15px !important;
    padding-right: 20px !important;
    width: 100% !important;
    display: -webkit-box !important;
    overflow: hidden !important;
    white-space: normal !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 2 !important;
    height: 2.8 !important;
    line-height: 1.4rem !important;
}

.add-to-group-divider {
    margin-top: 5px !important;
    margin-bottom: 10px !important;
    margin-left: 15px !important;
    margin-right: 20px !important;
}

.fabIcon {
    margin-right: 0px;
}

.fab {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    transition: 0.2s ease all !important;
}

.fab-span-extended {
    white-space: nowrap !important;
    overflow: hidden !important;
    opacity: 1 !important;
    margin-left: 5px !important;
}

.fab-span-round {
    width: 0 !important;
    opacity: 0 !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}

.fab-extended {
    right: 15px !important;
    bottom: 15px !important;
    margin: 8px !important;
    z-index: 10000 !important;
    position: fixed !important;
    color: #fff !important;
    height: 48px !important;
    background-color: #6ea139 !important;
}

.fab-extended:hover {
    background-color: #3E7203 !important;
}

.fab-round {
    right: 15px !important;
    bottom: 15px !important;
    margin: 8px !important;
    z-index: 10000 !important;
    position: fixed !important;
    color: #fff !important;
    width: 56px !important;
    height: 56px !important;
    border-radius: 50% !important;
    background-color: #6ea139 !important;
}

.fab-round:hover {
    background-color: #3E7203 !important;
}