/* #sv-interests-filter .react-swipeable-view-container > div {
	height: calc((var(--vh, 1vh) * 100) - 104px);
	width: 100%;
}
#sv-interests-filter .react-swipeable-view-container #searchTourtles {
	overflow: scroll !important;
	height: calc((var(--vh, 1vh) * 100) - 104px) !important;
	-webkit-overflow-scrolling: touch;
	width: 100%;
}
#sv-interests-filter .react-swipeable-view-container #toutleListBottomSpacing {
	display: none;
	width: 100%;
}
#sv-interests-filter .react-swipeable-view-container {
	height: calc((var(--vh, 1vh) * 100) - 104px) !important;
	width: 100%;
} */

.interests-swipeable-views {
	padding-top: 64px;
	/* position: absolute;
    top: 104px; */
}