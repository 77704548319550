.topbar-search-page {
    outline: 4px solid red;
}

.filter-results {
    padding-bottom: 90px;
}

.filter-actions-bottom {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    position: sticky;
    bottom: 5rem;
    width: fit-content;
    // left: 50%;
    // transform: translateX(-50%);
    margin: 0 auto;
    margin-bottom: 24px !important;


    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 6px;
    background-color: white;
    // width: 80vw;
    // max-width: 24rem;


    > div {
        flex: 0 0 auto;
        flex-wrap: wrap;
        justify-content: center;
    }

    .normal-case {
        text-transform: none;
    }
}
