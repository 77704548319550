/* Diagonal stacked paper effect */
.paper {
    background-color: #e6e6e6;
    /* Need position to allow stacking of pseudo-elements */
    position: relative;
    /* Padding for demo purposes */
    padding: 10px;
    width: 125px;
    height: 125px;
    float: left;
}

.paper,
.paper::before,
.paper::after {
    /* Add shadow to distinguish sheets from one another */
    box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.15);
}

.paper::before,
.paper::after {
    content: "";
    /* width: 125px;
  height: 125px; */
    position: absolute;
    background-color: #e6e6e6;
    z-index: 5;
}

/* Second sheet of paper */
.paper::before {
    left: 7px;
    top: -1px;
    z-index: 4;
}

/* Third sheet of paper */
.paper::after {
    left: 12px;
    top: -1px;
    z-index: 3;
}

/* Fourth sheet of paper */
.paper::after {
    left: 17px;
    top: -1px;
    z-index: 2;
}

/* Fifth sheet of paper */
.paper::after {
    left: 22px;
    top: -1px;
    z-index: 1;
}

.collection-list {
    flex-grow: 1;
}

.collection-container {
    margin-top: 15px !important;
    margin-bottom: 48px !important;
    position: relative;
    padding: 0 0.5rem;
}

.add-to-collection-title {
    margin-top: 85px !important;
    padding-left: 15px !important;
    padding-right: 20px !important;
    width: 100% !important;
    display: -webkit-box !important;
    overflow: hidden !important;
    white-space: normal !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 2 !important;
    height: 2.8 !important;
    line-height: 1.4rem !important;
}

.add-to-collection-divider {
    margin-top: 5px !important;
    margin-bottom: 10px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.collection-description-block {
    position: relative;
}

.collection-description-block p {
    margin-block-start: 0px;
    margin-block-end: 0px;
    line-height: 1.4rem;
    min-height: 19px;
}

.collection-description p {
    white-space: pre-wrap;
}
