.landing-page {
  flex-grow: 1;

  .app-bar {
    background-color: white !important;
    box-shadow: none !important;

    .toolbar {
      // height: 100px;
      margin-top: 40px;

      @include sm {
        padding-left: 5rem;
        padding-right: 5rem;
        // height: 150px;
      }
      
      .logo-container {
        margin-left: auto;
        margin-right: auto;
      }

      .pointer {
        cursor: pointer !important;
      }
    }
  }

  .pb-4 {
    padding-bottom: 4rem !important;
  }

  .grid-container {

    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0rem;

    @include md {
      padding-left: 4rem;
      padding-right: 4rem;
      padding-bottom: 1.8rem;
    }
  }

  .grid-item {
      padding-right: 1rem;
      padding-left: 1rem;
      margin-top: 2rem;
      margin-bottom: 2rem;


      &.header-grid-item {

        margin-top: 0rem;
        margin-bottom: 0rem;
        margin-left: -0.75rem;
        margin-right: -0.75rem;
        padding: 0;

        @include sm {
          padding-right: 1rem;
          padding-left: 1rem;
        }
      }

      &.carousel {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        cursor: pointer;

        @include sm {
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }

      @include sm {
        margin-top: 0rem;
        margin-bottom: 0rem;
      }

      .sign-up-button-container {
        text-align: center;
        @include sm {
          text-align: unset;
        }
      }

      .sign-up-button-container-footer {
        text-align: center;
      }

      .sign-up-button {
        margin-top: 1rem;
        margin-bottom: 1rem;
        text-transform: none !important;
      }

      .already-have-account {
        text-align: center;
        @include sm {
          text-align: unset;
        }
      }

      &.sign-up-container,
      &.already-signed-in-container {
        margin-top: 0rem;
      }

      .already-signed-in {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
  
      .sign-in-button {
        color: $primary;
        cursor: pointer;
      }

      .title-one {
        font-size: 2rem;
        font-weight: 500;

        @include sm {
          font-size: 3rem;
        }
      }

      .subtitle-one {
        font-size: 2rem;
        margin-bottom: 3rem;
        margin-top: 1rem;

        @include sm {
          font-size: 2.75rem;
          margin-bottom: unset;
        }
      }

      .header-image-container {
        width: 100%;
        margin-bottom: 1.8rem;
        
        @include sm {
          background-image: url('/image/blob.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        .header-image {
          max-width: 100%;

          @include sm {
            max-width: 80%;
            margin-left: 10%;
          }

          @include xl {
            max-width: 63%;
            margin-left: 18%;
          }
        }  
      }
      
      &.text-container {
        margin-top: 0.5rem;
        
        .text {
          font-weight: 500;

          &.mobile {
            margin-bottom: -1rem;
          }
        }
      }
    
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .privacy-subtitle-desktop {
    display: none;
    @include sm {
      display: block;
      font-size: 1.5rem; 
    }
  }

  .privacy-subtitle-mobile {
    display: block;
    font-size: 1.2rem; 
    @include sm {
      display: none;
    }
  }

  .carousel-container {
    margin-left: auto;
    margin-right: auto;
  }

  .carousel-text-web {
    display: none;
    @include sm {
      display: block;
    }
  }

  .carousel-text-mobile {
    display: block;
    @include sm {
      display: none;
    }
  }

  .segment {
    padding-top: 4rem;

    &__title {
      padding-top: 3rem;

      @include sm {
        padding-top: 4rem;
      }
    }

    &__columns {
      margin-top: 0rem;
      display: none;

      @include sm {
        display: block;
      }
    }

    &__rows {
      margin-top: 0rem;
      display: block;

      @include sm {
        display: none;
        height: 0;
      }
    }

    .title-one {
      font-size: 1.8rem;

        @include sm {
          font-size: 2.75rem;
        }
    }

    .footer-title {
      margin-bottom: 1rem;
    }

    .footer-logo {
      cursor: pointer;
    }

    .title-footer {
      font-size: 1.4rem;

        @include sm {
          font-size: 2.75rem;
        }
    }
    .footer-button {
      float: unset;
      font-size: 1.1rem;
      cursor: pointer;

      &.desktop {
        float: left;
        margin-right: 3rem;
      }

      &.left {
        margin-right: -1rem;;
      }

      &.right {
        margin-left: -1rem;;
      }
    }

    .footer-contact {
      font-size: 0.85rem;
      cursor: pointer;
      color: gray;
      margin-left: 1rem;
      @include sm {
        font-size: 1rem;
        margin-left: 4rem;
      }
      
    }

    .footer-copy {
      font-size: 0.85rem;
      cursor: pointer;
      color: gray;
      margin-right: 1rem;
      @include sm {
        font-size: 1rem;
        margin-right: 4rem;

      }
    }

    .footer-links {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 0;
      padding-top: 1.6rem;
      border-top: 1px solid gray;

      @include sm {
        margin-top: 2rem;
        margin-left: 4rem;
        margin-right: 4rem;
        float: left;
      }

      &.mobile {
        // display: none;
        @include sm {
          display: none;
        }
      }

      &.desktop {
        display: none;
        @include sm {
          display: block;
        }
      }
    }
  }

  .grey {
    background-color: #f1f1f1;
  }

  .background {
    @include sm {
      background-image: url('/image/blob.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .extra-bottom-padding {
    padding-bottom: 1.5rem;

    @include sm { 
      padding-bottom: 3rem;
    }
  }

  .less-top-padding {
    padding-top: 2rem;
  }

  .lesser-top-padding {
    padding-top: 1.4rem;
  }

  .faq { 
    padding-top: 1.5rem;
    padding-bottom: 1.8rem;
  }

  .faq-title {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
  }

  .faq-description {
    padding: 16px;
    font-size: 0.9rem;

    @include sm {
      font-size: 1rem
    }

    .faq-description-text {
      display: block;
      height: 100%;
    }
  }

  .faq-button {
    padding-top: 1rem;
    padding-top: 0rem;

    @include sm {
      padding-top: 1.5rem;
    }
  }

  .item-fonts {
    color: gray;
    padding: 1rem;
    border-left: 5px solid #f4772400;
    cursor: pointer;

    &.selected {
      color: #F47624;
      border-left: 5px solid #F47624;
    }
  }

  .item-title {
    color: gray;
    padding: 1rem;

    &.selected {
      color: #F47624;
    }
  }

  .item-subtext {
    color: gray;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    font-size: 1.2rem;

    &.selected {
      color: black;
    }
  }

  .item-container {
    border-left: 5px solid #f4772400;
    cursor: pointer;
    margin-bottom: -1rem;

    &.selected {
      color: #F47624;
      border-left: 5px solid #F47624;
    }
  }

  .item-container-mobile {
    margin-bottom: -1rem;
  }

  .privacy-image-container {
    width: 100%;
    margin-top: -4rem;

    @include sm {
      background-image: url('/image/blob.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-top: 0;
    }

    .privacy-image {
      max-width: 100%;

      @include sm {
        max-width: 54%;
        margin-left: 23%;
      }
    }
  }

  .example-tourtles-container {
    padding-top: 0rem;
    padding-bottom: 3rem;

    @include sm {
      padding-top: unset;
    }
  }

  .example-tourtles {

    &.web {
      display: none;
      @include md {
        display: block;
      }
    }

    &.mobile {
      display: block;
      @include md {
        display: none;
      }
    }
    
    &__title {
      margin: 1rem;
      margin-bottom: -0.5rem;
      padding-top: 0.5rem;
      @include sm {
        margin: 1rem;
        margin-top: 3rem;
        padding-top: 0;
      }
      // text-align: center;
    }
  }
}

.alice-carousel {
  background: url('/image/blob.svg') center no-repeat;
  background-size: contain;

  #{&} {
    &__dots {
      margin-top: -10px;
    }

    &__dots-item {
      &:not(.__custom) {
        background-color: #a5a5a5;

        &.__active {
          background-color: #F47624;
        }

        &:hover {
          background-color: #FDE8D8;
        }
      }
    }
  }
}

.carousel-image-container {
  width: 100%;
  text-align: center;

  &.mobile {
    margin-top: -1rem;
  }
}

.carousel-image {
  width: 100%;
  @include sm {
    width: 50%;
  }

}

.carousel-image-sticky {
  width: 100%;
  @include sm {
    width: 80%;
  }
}

.landing-page-tourtle-item {
  height: 100px;
  padding-bottom: 16px !important;

  &__wrapper {
    min-height: 91px;
    cursor: pointer;
    padding: .5rem;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    margin: 1rem;
    background-color: white;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  &-card {
    &#{&} {
        filter: unset;
        box-shadow: none;
        overflow: unset;
    }
  } 

  &__inner {
    &#{&} {
        display: flex;
        gap: 1rem;
        padding-bottom: unset !important;
        padding: 0;
    }
    // flex-wrap: nowrap;
}

  &__thumbnail {

  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    flex-grow: 1;
    width: calc(100% - 75px - 1rem); 
    padding-top: 0.25rem;// 100% - thumbnail - flex-gap
    max-height: 4.2rem;
    overflow: hidden;
  }

  &__title {
    &#{&} {
        color: $black;
        line-height: $font-medium;
        font-size: $font-small;
        font-weight: $font-weight-dark;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
  }

  &__description {
      line-height: 1rem;
      max-height: 2rem;

      &-blocked {
        margin-top: 0.25rem;
        font-size: $font-small;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;

          p {
              color: $black;
              margin-block-start: 0px;
              margin-block-end: 0px;
          }
      }
  }

  &__content-body {

  }
}