.title {
    margin-left: 0 !important;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    overflow-wrap: break-word;
    font-size: 1rem !important;
    vertical-align: middle;
    overflow: hidden;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    height: 48px;
    line-height: 14px !important;
    cursor: pointer;
    padding: 0 10px;
}